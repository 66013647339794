.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  color: #0f0;
  font-family: 'Courier New', monospace;
  position: relative;
  z-index: 1;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  font-size: 48px;
  text-align: center;
  margin-top: 100px;
  animation: blink 1s linear infinite;
}

.image {
  width: 100%;
  max-width: 600px;
  margin-top: 50px;
}

.paragraph {
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.flicker-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  background-color: #000;
  overflow: hidden;
}

.flicker-text {
  font-family: 'Courier New', monospace;
  font-size: 36px;
  color: #0f0;
  opacity: 0;
  animation-fill-mode: forwards;
}

.flicker-text-1 {
  animation: flicker 10s infinite;
}

.flicker-text-2 {
  animation: flicker 12s infinite;
}

.flicker-text-3 {
  animation: flicker 15s infinite;
}

.flicker-text-4 {
  animation: flicker 8s infinite;
}

.flicker-text-5 {
  animation: flicker 11s infinite;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0.8;
  }
  4% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
